<template>
  <div class="box" ref="vimg">
    <div
      class="background"
      :style="{'background-image':'url('+src+')','background-attachment':bgfixed?'fixed':'scroll'}"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    src: { type: String },
    bgfixed: { type: Boolean, default: false },
  },
  computed: {},
  mounted() {},
};
</script>
<style scoped>
.box {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
}
.background {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all ease-in-out 2s;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.background:hover {
  transform: scale(1.05);
}
</style>