<template>
  <Container page="news" :showLoading="showLoading">
    <!-- <pagetitle :title="txt.news[lang].title" /> -->
    <div style="height:4rem"></div>
    <div class="container grid-xl">
      <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished">
        <div class="columns item" v-for="(item,index) in list" :key="index">
          <div class="column col-1 col-md-12"></div>
          <div class="column col-4 col-md-12 left">
            <!-- <vimg :src="pics[index]"></vimg> -->
            <img :src="item.cover_img" />
          </div>
          <div class="column col-5 col-md-12 content">
            <h5>{{item[lang].title}}</h5>
            <p>{{item.publish_time}}</p>
            <p class="t-secondary" v-html="item[lang].content"></p>
          </div>
          <div class="column col-1 col-md-12"></div>
        </div>
      </vue-loadmore>
    </div>
  </Container>
</template>

<script>
// import vimg from '@/components/V-img.vue';
// import pagetitle from '@/components/PageTitle.vue';
import Container from '@/components/Container.vue';
import Pics from '@/assets/pic.js';
import txt from '@/assets/txt';

export default {
  name: 'Project',
  components: {
    // vimg,
    // pagetitle,
    Container,
  },
  data() {
    return {
      list: [],
      page: 0,
      finished: false,
      pics: Pics,
      showLoading: true,
      lang: localStorage.getItem('lang') || 'cn',
      txt,
    };
  },
  props: {},
  methods: {
    onRefresh(done) {
      this.list = [];
      this.page = 0;
      this.finished = false;
      this.getPost();
      done();
    },
    onLoad(done) {
      if (!this.finished) {
        this.getPost();
      }
      done();
    },

    async getPost() {
      try {
        const res = await this.$cloudbase.callFunction({
          name: 'get-list',
          data: {
            page: this.page,
            type: 'news',
          },
        });
        if (res.result.res.length < 9) {
          this.finished = true;
        } else {
          this.page += 1;
        }
        this.list = this.list.concat(res.result.res);
        this.showLoading = false;
      } catch (e) {
        console.error(e);
        this.list = e.message;
      }
    },
  },
  mounted() {
    this.getPost();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.item {
  margin-bottom: 40px;
}
.left {
  overflow: hidden;
  margin-bottom: 20px;
}
.left img {
  width: 100%;
}
.content h5 {
  margin-bottom: 1rem;
}

.t-secondary {
  margin-top: 2rem !important;
}

@media screen and (min-width: 840px) {
  .content {
    padding-left: 2rem;
  }
}
</style>
