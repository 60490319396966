export default {
    nav: {
        cn: ['项目', '关于', '新闻', '加入', '联系'],
        en: ['PROJECTS', 'ABOUT', 'NEWS', 'CAREERS', 'CONTACT']
    },
    title: {
        cn: '里 表 都 会',
        en: 'UMS'
    },
    ums: {
        cn: {
            title: "关于我们",
            about: "里表都会 UNLIMITED METROPOLIS 以创造设计价值为初衷，立足首都北京，并且努力成为中国最具艺术创意的设计机构，景观设计类型包括高端商业地产、多业态酒店、产业园区、现代居住社区，以及大尺度的公园、城市更新等，服务于万科，华润，保利，龙湖等多家优质的开发商。我们的设计不仅致力探索项目的趣味与多样性，同时在其他的设计领域极力探索行业边界，积攒多元化的设计实践经验，并且始终尝试不同领域的设计类型，希望通过设计可以整合零售，生活方式，美学体验等多种不同的方向，形成基于设计的载体，输出多元的价值。",
            awards: "获奖荣誉"
        },
        en: {
            title: "ABOUT",
            about: "Landscape design has been exploring the boundaries between nature, people, architecture, and cities, striving to soften the different spatial divisions, express the different spirit of the place through different material spaces, and solve the inherent problems of the site, create the unique tonal and landscape system belonging to the plot. We strive to find a local culture, natural, unique texture of the Earth, hope through modern design, drawing a sense of art, surprise, arouse the memory of the local culture space. Landscape design has been exploring the boundaries between nature, people, architecture, and cities, striving to soften the different spatial divisions, express the different spirit of the place through different materia. Landscape design has been exploring the boundaries between nature, people, architecture, and cities, striving to soften the different spatial divisions, express the different spirit of the place through different material spaces, and solve the inherent problems of the site, create thee system belonging to the plot. We strive to find a local culture, natural, unique texture of the Earth, hope through",
            awards: "AWARDS"
        }
    },
    careers: {
        cn: {
            title: "加入我们",
            txt: ["里表都会一直在寻找对景观设计、城市设计及规划设计有热情的人才。 我们期待那些渴望走在行业前沿的设计师加入我们 ; 里表都会一直在寻找对景观设计、城市设计及规划设计有热情的人才。 我们期待那些渴望走在行业前沿的设计师加入我们 ; 里表都会一直在寻找对景观设计、城市设计及规划设计有热情的人才。 我们期待那些渴望走在行业前沿的设计师加入我们 ; 里表都会一直在寻找对景观设计、城市设计及规划设计有热情的人才。 我们期待那些渴望走在行业前沿的设计师加入我们。", "我们希望可以给你的职业道路提供一个机会，加入我们吧。"]
        },
        en: {
            title: "careers",
            txt: ["Landscape design has been exploring the boundaries between nature, people, architecture,and cities, striving to soften the different spatial divisions, express the different spirit of theplace through different material spaces, and solve the inherent problems of the site, createthe unique tonal and landscape system belonging to the plot.We strive to find a local culture,natural, unique texture of the Earth, hope through modern design, drawing a sense of art,surprise, arouse the memory of the local culture space.", "Landscape design has been exploring the boundaries between nature, people, architecture, and cities, striving to soften the different spatial divisions, express the different spirit of the place through different materia."]
        }
    },
    contact: {
        cn: {
            title: "里表都会",
            location: "北京市朝阳区建外 SOHO 西区 18 号楼 2306",
            phone: "+86 010-8888888",
            mail: "info@umsstudio.com",
            bd_txt: "商务接洽联系:",
            bd_mail: "BD@umsstudio.com",
            job_mail_txt: "简历投递邮箱:",
            job_mail: "HR@umsstudio.com"
        },
        en: {
            title: "UNLIMITED METROPOLIS",
            location: "xxxxxxxxxxxxxxxxxxx, Beijing",
            phone: "+86 010-8888888",
            mail: "info@umsstudio.com",
            bd_txt: "For business inquiries contact:",
            bd_mail: "BD@umsstudio.com",
            job_mail_txt: "For positions inquiries contact: :",
            job_mail: "HR@umsstudio.com"
        }
    },
    news: {
        cn: {
            title: "新闻动态"
        },
        en: {
            title: "NEWS"
        }
    },
    projects_detail: {
        cn: ['项目地点', '建成时间', '项目类型', '项目规模', '业主单位', '施工单位'],
        en: ['Location', 'Year', 'Type', 'Size', 'Client', 'Collaborators']
    }
}