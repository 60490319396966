<template>
  <Container class="container grid-xl">
    <div style="height:2rem"></div>
    <div class="columns">
      <div class="column col-1"></div>
      <div class="column col-8 col-md-12">
        <h4>{{data[lang].title}}</h4>
        <div v-html="data[lang].content"></div>

        <!-- <h4>资深景观设计师</h4>
        <p>请使用以下格式作为邮件申请的主题:</p>
        <p>姓名 _ 所申请的职位</p>
        <p>姓名·高级景观设计师</p>
        <h6>职位</h6>
        <p>高级景观设计师</p>
        <h6>岗位描述</h6>
        <p>参与各种类型和规模的项目，包括城市景观规划、大型和小型公共开放空间、机构和校园景观、商业和混合用途景观设计、滨水和修复项目。</p>
        <p>在整个项目范围内管理团队，从概念设计到施工。</p>
        <p>每天进行演示，参加会议，与客户，ldi 和顾问进行协调。</p>
        <p>与其他高级设计师或主管沟通和协调。</p>
        <h6>岗位职责</h6>
        <p>参与各种类型和规模的项目，包括城市景观规划、大型和小型公共开放空间、机构和校园景观、商业和混合用途景观设计、滨水和修复项目。</p>
        <p>在整个项目范围内管理团队，从概念设计到施工。</p>
        <p>每天进行演示，参加会议，与客户，ldi 和顾问进行协调。</p>
        <p>与其他高级设计师或主管沟通和协调。</p>-->
      </div>
    </div>
    <div style="height:4rem; width:100%;"></div>
    <div class="columns">
      <div class="column col-1"></div>
      <div class="column col-11 submit">
        <a :href="'mailto:'+txt.contact[lang].job_mail" class="btn btn-success">投递简历</a>
        <!-- <p>或发送邮件至：{{txt.contact[lang].mail}}</p> -->
      </div>
    </div>
    <div style="height:5rem; width:100%;"></div>
  </Container>
</template>
<script>
import Container from '@/components/Container';
import txt from '@/assets/txt';

export default {
  data() {
    return {
      lang: localStorage.getItem('lang') || 'cn',
      txt,
      data: {},
    };
  },
  components: {
    Container,
  },
  methods: {
    async getDetail(id) {
      try {
        const res = await this.$cloudbase.callFunction({
          name: 'get-detail',
          data: { id, type: 'jobs' },
        });
        this.data = res.result.res;
        document.title = res.result.res[this.lang].title + ' | 里表都会';
        localStorage.setItem(id, JSON.stringify(this.content));
      } catch (e) {
        this.data = e.message;
      }
    },
  },

  mounted() {
    const id = this.$route.params.id.slice(0, -5);
    this.getDetail(id);
  },
};
</script>
<style scoped>
h4 {
  margin: 3rem 0rem;
}
h6 {
  margin: 3rem 0rem 1rem;
}

.submit {
  margin-top: 2rem;
}

.submit a {
  color: #333 !important;
}
</style>