<template>
  <div class="container grid-xl" style="margin-top:3rem;margin-bottom:3rem;">
    <div class="columns">
      <div class="column col-1 hide-md"></div>
      <div class="column col-10">
        <h3>{{title}}</h3>
        <p>UNLIMITED METROPOLIS</p>
      </div>
      <div class="column col-1 hide-md"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: 'UMS' },
  },
};
</script>
<style scoped>
p {
  margin-top: -0.5rem !important;
}
</style>