<template>
  <Container
    page="projects"
    :showLoading="showLoading"
    class="container grid-xl"
  >
    <!-- <pagetitle :title="txt.news[lang].title" /> -->
    <div class="selector">
      <h6
        :style="{
          color: selected == '' ? '#86ff00' : '#3b4351',
          'font-weight': selected == '' ? 'bold' : 'lighter !important',
        }"
        @click="selectYear('')"
      >
        ALL
      </h6>
      <h6
        :style="{
          color: selected == item.years ? '#86ff00' : '#3b4351',
          'font-weight': selected == item.years ? 'bold' : 'lighter !important',
        }"
        @click="selectYear(item.years)"
        v-for="(item, index) in years"
        :key="index"
      >
        {{ item.years }}
      </h6>
    </div>
    <vue-loadmore
      :on-refresh="onRefresh"
      :on-loadmore="onLoad"
      :finished="finished"
      ref="cimg"
    >
      <div class="columns">
        <router-link
          :to="'/detail/' + item._id + '.html'"
          class="column col-4 col-md-6 col-sm-12"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="card">
            <div class="card-image" v-proportion="0.56">
              <vimg :src="item.cover_img" />
            </div>
            <h6 style="color: #3b4351">{{ item[lang].title }}</h6>
          </div>
        </router-link>
      </div>
    </vue-loadmore>
  </Container>
</template>

<script>
import vimg from "@/components/V-img.vue";
import Pics from "@/assets/pic.js";
import txt from "@/assets/txt.js";
import Container from "@/components/Container";
// import pagetitle from '@/components/PageTitle.vue';

export default {
  name: "Project",
  components: {
    vimg,
    Container,
  },
  data() {
    return {
      list: [],
      page: 0,
      finished: false,
      pics: Pics,
      selected: "",
      years: [],
      showLoading: true,
      txt,
      vHeight: 0, //主体宽度
    };
  },
  computed: {
    lang() {
      return localStorage.getItem("lang") || "cn";
    },
  },
  props: {},
  methods: {
    onRefresh(done) {
      this.list = [];
      this.page = 0;
      this.finished = false;
      this.getPost();
      done();
    },
    onLoad(done) {
      if (!this.finished) {
        this.getPost();
      }
      done();
    },

    async getPost() {
      try {
        const res = await this.$cloudbase.callFunction({
          name: "get-list",
          data: {
            page: this.page,
            type: "projects",
            year: this.selected,
            pageSize: 18,
          },
        });
        if (res.result.res.length < 9) {
          this.finished = true;
        } else {
          this.page += 1;
        }
        this.list = this.list.concat(res.result.res);
        this.showLoading = false;
      } catch (e) {
        console.error(e);
        this.list = e.message;
      }
    },
    async getYears() {
      try {
        const res = await this.$cloudbase.callFunction({
          name: "get-list",
          data: {
            type: "years",
          },
        });
        this.years = res.result.res;
      } catch (e) {
        this.list = e.message;
      }
    },
    selectYear(data) {
      if (data !== this.selected) {
        this.showLoading = true;
        this.list = [];
        this.page = 0;
        this.finished = false;
        this.selected = data;
        this.getPost();
      }
    },
  },
  mounted() {
    this.getPost();
    this.getYears();
    this.vHeight = ((this.$refs.cimg.$el.clientWidth - 34) / 3 / 16) * 9;
    window.onresize = () => {
      return (() => {
        this.vHeight = ((this.$refs.cimg.$el.clientWidth - 34) / 3 / 16) * 9; //
      })();
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  margin-bottom: 40px;
  border: none !important;
  transition: all ease-in-out 3s;
}

.card-image {
  overflow: hidden;
  height: 300px;
  margin-bottom: 1rem;
}

.selector {
  margin: 40px 0px;
  display: block;
  width: 100%;
}

.selector > h6 {
  display: inline-block;
  padding-right: 20px;
}

.selector > h6:hover {
  cursor: pointer;
}
</style>
