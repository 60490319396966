<template>
  <div>
    <Navigation class="index" />
    <template>
      <div class="columns col-gapless">
        <div
          class="column col-12"
          style="
            position: absolute;
            height: 100%;
            top: 0px;
            left: 0px;
            z-index: -1;
          "
        >
          <slider
            animation="fade"
            class="index"
            style="height: 100%"
            indicators="left"
          >
            <slider-item v-for="(item, index) in list" :key="index">
              <router-link
                :to="'/detail/' + item.id + '.html'"
                class="cover hide-sm"
                :style="{'background-image': 'url(' + item.cover_img + ')',
                }"
              >
                <div class="columns title">
                  <div class="column col-2"></div>
                  <div class="column col-10">
                    <h1>UNLIMITED</h1>
                    <h1>METROPOLIS</h1>
                  </div>
                </div>
              </router-link>
              <router-link
                :to="'/detail/' + item._id + '.html'"
                class="cover show-sm"
                :style="{
                  'background-image': 'url(' + item.cover_img_phone + ')',
                }"
              ></router-link>
            </slider-item>
          </slider>
          <div class="container grid-xl " style="position: fixed;bottom: 20px;width: 100%;z-index: 999;">
            <div class="columns footer">
              <div class="column col-12 beian">
                <a style="color:white !important;" href="https://beian.miit.gov.cn/" target="_blank">京ICP备2022023198号-2</a>
                <span class="right">
                  <img src="https://www.beian.gov.cn/img/new/gongan.png">
                  <span>京公网安备 11010502051659</span>
                </span>
              </div>
            </div>

            
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Slider, SliderItem } from "vue-easy-slider";
import Pics from "@/assets/pic.js";
import Navigation from "@/components/Navigation.vue";

export default {
  name: "Index",
  components: {
    Slider,
    SliderItem,
    Navigation,
  },
  data() {
    return {
      list: [],
      pics: Pics,
    };
  },
  methods: {
    async getPost() {
      try {
        const res = await this.$cloudbase.callFunction({
          name: "get-list",
          data: { type: "highlight" },
        });
        this.list = res.result.res;
      } catch (e) {
        console.error(e);
        this.list = e.message;
      }
    },
  },
  mounted() {
    this.getPost();
  },
};
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
/* .cover:hover {
  transform: scale(1.2);
} */

.title {
  position: fixed;
  color: white;
  top: 45%;
  left: 6rem;
}

.title h1 {
  font-size: 3rem !important;
  line-height: 4rem !;
}

.title h1:first-child {
  font-weight: lighter;
  margin-bottom: 2rem;
}

.beian{
  display: flex;
  align-items: center;
  justify-content: center;
  color:white !important;
  text-align:center;
  .right{
    margin-left: 24px;
    display: flex;
  align-items: center;
    img{
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 4px;
  }

  }

}
</style>
