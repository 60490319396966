import Vue from "vue";
import App from "./App.vue";
import Cloudbase from "@cloudbase/vue-provider";
import router from './router'
import VueLoadmore from 'vuejs-loadmore';
import less from 'less'
import spectre from 'spectre.css'
import proportion from 'vue-proportion-directive'


Vue.use(spectre)
Vue.use(proportion)



// 注意更新此处的TCB_ENV_ID为你自己的环境ID
window._tcbEnv = window._tcbEnv || { TCB_ENV_ID: "design-7g5ztov83dbca5cc" };

export const envId = window._tcbEnv.TCB_ENV_ID;
export const region = window._tcbEnv.TCB_REGION;

Vue.use(VueLoadmore);
Vue.use(less)


Vue.config.productionTip = false;
Vue.prototype.router = router

// Vue.use(router)

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  window.scrollTo(0, 0)
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})


Vue.use(Cloudbase, {
  env: envId,
  region: region
});


import VueFullpage from 'vue-fullpage.js';
Vue.use(VueFullpage);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
