<template>
  <Container page="careers">
    <cimg page="careers" />
    <!-- <pagetitle :title="txt.careers[lang].title" /> -->
    <div style="background:white;">
        <div class="container grid-xl careers">
          <div class="columns">
            <div class="column col-1 hide-md"></div>
            <div class="column col-6 col-md-12">
              <!-- <p v-for="(item,index) in txt.careers[lang].txt" :key="index">{{item}}</p> -->
              <div v-for="(item,index) in list" :key="index" style="margin-bottom:6rem">
                <h5 style="margin-bottom:2rem">{{item[lang].title}}</h5>
                <div v-html="item[lang].content"></div>
                <div style="height:1rem"></div>
                <a :href="'mailto:'+txt.contact[lang].job_mail" class="btn btn-success">SEND</a>
              </div>
            </div>
            <div class="column col-1 col-md-12" style="height:2rem;"></div>
          </div>
          <div style="height:6rem;width:100%"></div>
        </div>
    </div>

  </Container>
</template>
<script>
import Container from '@/components/Container.vue';
// import pagetitle from '@/components/PageTitle.vue';
import txt from '@/assets/txt';
import cimg from '@/components/CoverImg';

export default {
  data() {
    return {
      list: [],
      txt,
      lang: localStorage.getItem('lang') || 'cn',
    };
  },
  components: {
    // pagetitle,
    Container,
    cimg,
  },
  methods: {
    async getPost() {
      try {
        const res = await this.$cloudbase.callFunction({
          name: 'get-list',
          data: {
            page: this.page,
            pageSize: 1000,
            type: 'jobs',
          },
        });
        this.list = res.result.res;
      } catch (e) {
        console.error(e);
        this.list = e.message;
      }
    },
  },
  mounted() {
    this.getPost();
  },
};
</script>
<style scoped>
.container {
  background-color: white;
  padding-top: 6rem !important;
}
h6 {
  margin-bottom: 0.8rem;
}

h5 {
  margin-bottom: 1rem;
}

/* .jobs a h6 {
  color: #3b4351 !important;
}

.jobs a h6:hover {
  color: #757575 !important;
} */

.btn {
  margin-top: 2rem;
}
</style>