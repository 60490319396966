<template>
  <div style="height:114px;">
    <header class="navbar-c hide-md">
      <div class="container" style="max-width: 160rem !important">
        <div class="navbar navbar-pc">
          <section class="navbar-section">
            <router-link class="navbar-brand mr-2" to="/" style="margin-left: 0rem">
              <div v-if="lang == 'cn'">
                <img src="../assets/logo_cn.png" style="width: 120px" class="logo_other" />
                <img src="../assets/logo_white_cn.png" style="width: 120px;" class="logo_home" />
              </div>
              <div v-else>
                <img src="../assets/logo_en.png" style="width: 120px" class="logo_other" />
                <img src="../assets/logo_white_en.png" style="width: 120px" class="logo_home" />
              </div>
            </router-link>
          </section>
          <section class="navbar-section">
            <router-link class to="/projects">
              <div class="nav-name" :style="{'font-weight': active == 'projects' ? 'bold' : 'lighter'}">
                {{ txt.nav[lang][0] }}
              </div>
              <div class="active" v-if="active == 'projects'"></div>
            </router-link>

            <router-link class to="/ums">
              <div class="nav-name" :style="{ 'font-weight': active == 'ums' ? 'bold' : 'lighter' }">
                {{ txt.nav[lang][1] }}
              </div>
              <div class="active" v-if="active == 'ums'"></div>
            </router-link>
            <router-link class to="/news">
              <div class="nav-name" :style="{
                'font-weight': active == 'news' ? 'bold' : 'lighter',
              }">
                {{ txt.nav[lang][2] }}
              </div>
              <div class="active" v-if="active == 'news'"></div>
            </router-link>
            <router-link class to="/careers">
              <div class="nav-name" :style="{
                'font-weight': active == 'careers' ? 'bold' : 'lighter',
              }">
                {{ txt.nav[lang][3] }}
              </div>
              <div class="active" v-if="active == 'careers'"></div>
            </router-link>
            <!-- <div class="popover popover-bottom">
              <div class="about">
                <div class="nav-name" :style="{'font-weight': active=='about'?'bold':'lighter'}">{{txt.nav[lang][1]}}</div>
                <div class="active" v-if="active=='about'"></div>
              </div>
              <div class="popover-container">
                <router-link class to="/ums">{{txt.nav[lang][3]}}</router-link>
                <router-link class to="/news">{{txt.nav[lang][4]}}</router-link>
                <router-link class to="/careers">{{txt.nav[lang][5]}}</router-link>
              </div>
            </div>-->
            <router-link to="/contact">
              <div class="nav-name" :style="{
                'font-weight': active == 'contact' ? 'bold' : 'lighter',
              }">
                {{ txt.nav[lang][4] }}
              </div>
              <div class="active" v-if="active == 'contact'"></div>
            </router-link>
            <div class="lang">
              <span :style="{ 'font-weight': lang == 'en' ? 'bold' : 'lighter' }" @click="setLanguage('en')">EN</span>
              <span style="font-weight: lighter">/</span>
              <span :style="{ 'font-weight': lang == 'cn' ? 'bold' : 'lighter' }" @click="setLanguage('cn')">中</span>
            </div>
          </section>
        </div>
      </div>
      <div style="width: 100%; height: 0rem"></div>
    </header>

    <!-- 手机端导航栏 -->
    <header class="mobile-nav show-md" :style="{ right: showNav ? '0px' : '-100%' }">

      <img src="../assets/close-fill_2.svg" class="close" @click="showMobileNav" />
      <router-link to="/" style="margin-top:50px;">{{ lang == "cn" ? "首页" : "INDEX" }}</router-link>
      <router-link to="/projects">{{ txt.nav[lang][0] }}</router-link>
      <router-link to="/ums">{{ txt.nav[lang][1] }}</router-link>
      <router-link to="/news">{{ txt.nav[lang][2] }}</router-link>
      <router-link to="/careers">{{ txt.nav[lang][3] }}</router-link>
      <router-link to="/contact">{{ txt.nav[lang][4] }}</router-link>
      
      <div class="lang" style="margin-top: 2rem">
        <span :style="{ color: lang == 'en' ? '#86ff00 !important' : '#3b4351  !important' }"
          @click="setLanguage('en')">EN</span>
        <span style="color:#3b4351  !important">/</span>
        <span :style="{ color: lang == 'cn' ? '#86ff00 !important' : '#3b4351  !important' }"
          @click="setLanguage('cn')">中</span>
      </div>

    </header>
    <div class="show-md nav-menu navbar-c">
      <div class="container">
        <div class="navbar">
          <section class="mobile-navbar-section">
            <router-link class="navbar-brand mr-2" to="/" style="margin-left:0rem;">
              <!-- <h4>{{ txt.title[lang] }}</h4> -->
                <!-- <div class="title">{{txt.title[lang]}}</div> -->
                <div v-if="lang == 'cn'">
                  <img src="../assets/logo_cn.png" style="width: 120px" class="logo_other" />
                  <img src="../assets/logo_white_cn.png" style="width: 120px;" class="index" />
                </div>
                <div v-else>
                  <img src="../assets/logo_en.png" style="width: 120px" class="logo_other" />
                  <img src="../assets/logo_white_en.png" style="width: 120px;" class="index" />
                </div>
            </router-link>
          </section>
          <section class="mobile-navbar-section" @click="showMobileNav" v-if="!showNav">
            <img src="../assets/menu-line.svg" class="logo_home" />
            <img src="../assets/menu-line_dark.svg" class="logo_other" />
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import txt from "@/assets/txt";
export default {
  name: "Navigation",
  data() {
    return {
      lang: localStorage.getItem("lang") || "cn",
      txt,
      showNav: false,
    };
  },
  props: { active: { type: String, default: null } },
  computed: {},
  methods: {
    setLanguage(arr) {
      localStorage.setItem("lang", arr);
      this.lang = arr;
      location.reload();
    },
    showMobileNav() {
      this.showNav = !this.showNav;
    },
  },
  mounted() {
    this.lang = localStorage.getItem("lang") || "cn";
    //项目详情页，项目导航页加绿条
    // if (this.$route.path.split('/')[1] == 'detail' || this.$route.path.split('/')[1] == 'detail2') {
    //   this.active = 'projects';
    // }
  },
};
</script>

<!-- Add "scoped" attribute too limit CSS to this component only -->
<style scoped>
.title {
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: inherit;
  font-weight: 500;
  height: 1.2rem;
  overflow: hidden;
}

.navbar-pc {
  padding: 0rem 0.8rem;
  margin: 1.5rem 0rem;
}

.navbar-c {
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: white;
}

/* .navbar {
  padding: 0px 20px;
} */

.navbar a {
  /* color: white !important; */
  text-decoration: none;
  color: #3b4351 !important;
  position: relative;
  margin: 1.2rem 0rem;
  transition: opacity ease-in-out 0.5s;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-navbar-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.navbar-section a {
  margin: 0rem 1.4rem;
}

.navbar a:active,
.navbar a:focus {
  text-decoration: none !important;
  box-shadow: none;
}

.navbar a:hover,
.about:hover,
.lang:hover {
  text-decoration: none !important;
  opacity: 0.8;
}

.navbar .nav-name {
  padding: 0.6rem 0rem;
}

.about {
  transition: opacity ease-in-out 0.5s;
  cursor: pointer;
  padding: 1rem 0px;
}

.active {
  width: 100%;
  border-bottom: 4px solid #86ff00;
  position: absolute;
  left: 0px;
}

.popover-bottom div {
  color: #3b4351;
}

.popover .popover-container {
  width: auto !important;
  background-color: white !important;
  padding: 0px !important;
}

.popover .popover-container a {
  display: block;
  padding: 1rem 2rem;
  color: #3b4351 !important;
  font-weight: lighter !important;
  text-decoration: none;
  text-align: center;
  width: 160px;
}

.popover .popover-container a:hover {
  text-decoration: none;
  background-color: #86ff00;
}

.lang {
  color: #3b4351;
  transition: opacity ease-in-out 0.5s;
  cursor: pointer;
}

.lang span {
  padding: 0px 2px;
  color: #3b4351;
}

.index {
  background-color: transparent;
}

/* 首页导航栏文字颜色 */
.index .navbar a,
.index .about,
.index .lang span,
.index .popover-bottom div {
  color: white !important;
}

.index .navbar-c {
  background: transparent !important;
}

.index .popover-container a {
  color: #3b4351 !important;
}

.index .logo_home {
  display: block !important;
}

.index .logo_other {
  display: none !important;
}

.logo_home {
  display: none;
}

.navbar-section>.menu {
  width: 2rem;
  height: 2rem;
}

/* 手机导航栏 */
/* .mobile-nav-c {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: -1;
  transition: all ease-in-out 0.5s;
} */

.mobile-nav {
  position: fixed;
  right: -100%;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1001;
  color: #3b4351;
  transition: right ease-in-out 0.8s;
  padding: 1.5rem 2rem;
}

.mobile-nav>.close {
  margin-bottom: 1rem;
  margin-top: 2rem;
  width: 2rem;
  height: 2rem;
}

/* .mobile-nav::after {
  content: '';
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: -1;
} */

.mobile-nav h4 {
  margin-bottom: 2rem;
}

.mobile-nav div {
  display: block;
  margin-bottom: 1.2rem;
  color: #3b4351 !important;
  text-align: center;
}

.mobile-nav a {
  display: block;
  font-weight: lighter !important;
  padding: 1rem 0rem;
  color: #3b4351 !important;
  text-align: center;
}

.nav-menu {
  position: fixed;
}

.nav-menu i {
  color: #3b4351;
}

/* 
.nav-menu img {
  width: 1rem;
  height: 1.6rem;
} */

.index .nav-menu i {
  color: #fff;
}
</style>
