<template>
  <Container class>
    <div>
      <div style="width:100%;height:2rem;"></div>
      <slider animation="fade" style="width:100%" v-proportion="0.56">
        <slider-item v-for="(item, index) in [1,2,2,2]" :key="index">
          <router-link
            :to="'/detail/'+item._id+'.html'"
            class="cover hide-sm"
            :style="{'background-image':'url('+pics[index]+')'}"
          ></router-link>
          <router-link
            :to="'/detail/'+item._id+'.html'"
            class="cover show-sm"
            :style="{'background-image':'url('+item.cover_img_phone+')'}"
          ></router-link>
        </slider-item>
      </slider>
      <div class="container grid-xl" style="margin-top:8rem;padding-bottom:8rem !important">
        <div class="columns">
          <div class="column col-1"></div>
          <div class="column col-10 col-md-12" style="margin:3rem 0px;">
            <h5>{{data[lang].title}}</h5>
          </div>
        </div>
        <div class="columns">
          <div class="column col-1"></div>

          <div class="column col-7 col-md-12">
            <div v-html="data[lang].content"></div>
          </div>
          <div class="column col-1 hide-md"></div>
          <div class="column col-3 col-md-12 info">
            <div>
              <h6>{{txt.projects_detail[lang][0]}}:</h6>
              <p>Beijing, Beijing</p>
            </div>
            <div>
              <h6>{{txt.projects_detail[lang][1]}}:</h6>
              <p>Beijing, Beijing</p>
            </div>
            <div>
              <h6>{{txt.projects_detail[lang][2]}}:</h6>
              <p>Beijing, Beijing</p>
            </div>
            <div>
              <h6>{{txt.projects_detail[lang][3]}}:</h6>
              <p>Beijing, Beijing</p>
            </div>
            <div>
              <h6>{{txt.projects_detail[lang][4]}}:</h6>
              <p>Beijing, Beijing</p>
            </div>
            <div>
              <h6>{{txt.projects_detail[lang][5]}}:</h6>
              <p>Beijing, Beijing</p>
            </div>
            <div>
              <h6>{{txt.projects_detail[lang][6]}}:</h6>
              <p>Beijing, Beijing</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import Container from '@/components/Container';
import { Slider, SliderItem } from 'vue-easy-slider';
import pics from '@/assets/pic.js';
import txt from '@/assets/txt.js';

export default {
  data() {
    return {
      data: '',
      pics,
      prevew: false,
      txt,
      lang: localStorage.getItem('lang') || 'cn',
    };
  },
  components: { Container, Slider, SliderItem },
  props: {},
  methods: {
    async getDetail(id) {
      //如有本地缓存则读取本地缓存
      // const content = localStorage.getItem(id);
      // if (content !== null && content.length > 0) {
      //   this.content = JSON.parse(content);
      //   return;
      // }
      console.log(id);
      //如无则使用接口数据
      try {
        const res = await this.$cloudbase.callFunction({
          name: 'get-detail',
          data: { id },
        });
        this.data = res.result.res;
        document.title = res.result.res[this.lang].title + ' | 里表都会';
        localStorage.setItem(id, JSON.stringify(this.content));
      } catch (e) {
        this.data = e.message;
      }
    },
    showPreview() {
      this.prevew = !this.prevew;
    },
  },

  mounted() {
    const id = this.$route.params.id.slice(0, -5);
    this.getDetail(id);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrapper {
  background-color: #fafafa;
  padding: 2rem 0rem;
}

/* .cover {
  background-color: #000;
} */

.preview {
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 9999;
  padding: 10rem;
}

.info > div {
  margin-bottom: 0.2rem;
}

.info p,
.info h6 {
  display: inline-block;
}

.info h6 {
  margin-right: 0.5rem;
}
</style>
