<template>
  <div id="app">
    <!-- <div style="height:114px;width:100%;"></div> -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* .container {
  padding-left: 0px !important;
  padding-right: 2px !important;

} */

.container.grid-xl {
  max-width: 140rem !important;
  padding: 0rem 1rem;
}

.slider-indicator-icon {
  width: 9px !important;
  height: 9px !important;
  margin: 0 0.2rem !important;
}

.slider-icon {
  width: 10px !important;
  height: 10px !important;
}

.media-wrap,
.image-wrap {
  width: 100% !important;
  overflow: hidden;
}

.media-wrap img {
  width: 100%;
}

p {
  font-weight: light;
  font-weight: thin;
  font-weight: lighter;
}

a {
  text-decoration: none;
}

a:active,
a:focus,
a:hover {
  text-decoration: none !important;
  box-shadow: none !important;
}

p {
  margin: 0 0 0.4rem !important;
}

h6 {
  font-weight: bold !important;
}

.t-secondary {
  color: #616161;
}

.slider a {
  display: block;
}

.slider .cover {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  transition: all ease-in-out 1.5s;
  background-color: #000;
}

.vuejs-loadmore-finished-text,
.vuejs-loadmore {
  font-size: 0.8rem !important;
  color: #9e9e9e !important;
  font-weight: lighter !important;
}

.awards .items li {
  font-weight: lighter !important;
}
.awards .items ul,
.items li {
  list-style: none !important;
}

.awards .items ul {
  margin: 0rem;
  padding: 0;
}

.detail p,
.detail .MsoNormal {
  margin-bottom: 1.8rem !important;
}

.t-secondary p {
  margin-bottom: 2rem !important;
}

.contact p,
.careers p {
  min-height: 1rem !important;
}

/* for PC only */
@media screen and (min-width: 600px) {
  .slider-btn {
    opacity: 0;
    transition: all ease-in-out 1s;
  }

  .slider:hover .slider-btn {
    opacity: 1;
  }

  .slider-indicator-active,
  .slider-btn {
    transition: all ease-in-out 0.5s;
  }

  .slider:hover .slider-indicator-active {
    background-color: rgba(255, 255, 255, 0.7) !important;
  }

  .navbar-section > a,
  .navbar-section > div:not(:last-child) {
    margin-right: 50px;
  }

  .index .slider-indicators {
    left: 9.1rem !important;
    top: calc(50% + 7rem);
  }

  .index h1 {
    font-size: 4rem !important;
  }

  .index h1:last-child {
    margin-top: -3rem !important;
  }

  #project-pics .column {
    padding-left: 0.1rem !important;
    padding-right: 0.1rem !important;
  }
  #project-pics .column .box {
    margin-bottom: 0.1rem !important;
  }
}

@media screen and (max-width: 1280px) {
  .container {
    padding: 0px 0.6rem !important;
  }
}
</style>
