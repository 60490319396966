import Vue from 'vue'
import Router from 'vue-router'
import News from "@/pages/News.vue"
import Index from "@/pages/Index.vue"
import careers from "@/pages/Careers.vue"
import Projects from "@/pages/Projects.vue"
import Detail from "@/pages/Detail.vue"
import Detail2 from "@/pages/Detail2.vue"
import Contact from "@/pages/Contact.vue"
import Ums from "@/pages/Ums.vue"
import CarrersDetail from "@/pages/CarrersDetail.vue"


Vue.use(Router)

export default new Router({
    // mode: "history",
    routes: [{
        path: '/',
        name: 'Index',
        component: Index,
        meta: { title: '里表都会' }
    }, {
        path: '/projects',
        name: 'Projects',
        component: Projects,
        meta: { title: '项目 | 里表都会' }
    }, {
        path: '/news',
        name: 'News',
        component: News,
        meta: { title: '新闻 | 里表都会' }

    }, {
        path: '/careers',
        name: 'careers',
        component: careers,
        meta: { title: '加入 | 里表都会' }

    }, {
        path: '/careers/detail/:id',
        name: 'CarrersDetail',
        component: CarrersDetail,
        meta: { title: '加入 | 里表都会' }
    }, {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        meta: { title: '联系我们 | 里表都会' }
    }, {
        path: '/ums',
        name: 'Ums',
        component: Ums,
        meta: { title: '关于我们 | 里表都会' }
    }, {
        path: '/detail/:id',
        name: 'Detail',
        component: Detail,
    }, {
        path: '/detail2/:id',
        name: 'Detail2',
        component: Detail2,
    }]

})
