<template>
  <Container page="projects">
    <div class="cover">
      <div style="width:100%" v-proportion="0.56"></div>
      <vimg class="img show-md" :src="data.cover_img" :bgfixed="true" v-proportion="0.56" />
      <vimg class="img hide-md" :src="data.cover_img_phone" :bgfixed="true" v-proportion="0.56" />
    </div>

    <div style="background-color:white;">
      <div class="container grid-xl" style="padding-bottom:8rem !important; padding-top:7rem !important">
        <div class="columns">
          <div class="column col-1"></div>
          <div class="column col-11 col-md-12">
            <h5>{{data[lang].title}}</h5>
          </div>
        </div>
        <div style="height:3rem"></div>
        <div class="columns">
          <div class="column col-1 hide-md"></div>
          <div class="column col-10 col-md-12">
            <div class="columns">
              <div class="column col-8 col-md-12 detail">
                <div v-html="data[lang].content"></div>
              </div>
              <div class="column col-1 hide-md"></div>
              <div class="column col-3 col-md-12 info">
                <div style="height:3rem" class="show-md"></div>
                <div v-if="data[lang].location">
                  <h6>{{txt.projects_detail[lang][0]}}:</h6>
                  <p>{{data[lang].location}}</p>
                </div>
                <div v-if="data[lang].status">
                  <h6>{{txt.projects_detail[lang][1]}}:</h6>
                  <p>{{data[lang].status}}</p>
                </div>
                <div v-if="data[lang].type">
                  <h6>{{txt.projects_detail[lang][2]}}:</h6>
                  <p>{{data[lang].type}}</p>
                </div>
                <div v-if="data[lang].size">
                  <h6>{{txt.projects_detail[lang][3]}}:</h6>
                  <p>{{data[lang].size}}</p>
                </div>
                <div v-if="data[lang].client">
                  <h6>{{txt.projects_detail[lang][4]}}:</h6>
                  <p>{{data[lang].client}}</p>
                </div>
                <div v-if="data[lang].collaborators">
                  <h6>{{txt.projects_detail[lang][5]}}:</h6>
                  <p>{{data[lang].collaborators}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper" id="project-pics">
        <div class="container grid-xl">
          <div class="columns">
            <div class="column col-1 hide-md"></div>
            <div class="column col-10 col-md-12">
              <div class="columns">
                <div
                  class="column col-3 col-md-4 col-sm-12 imgs"
                  v-for="(item,index) in data.pics"
                  :key="index"
                  @click="showPreview(index)"
                >
                  <vimg :src="item" style="margin-bottom:1rem" v-proportion="0.56" />
                </div>
              </div>
            </div>
            <div class="column col-1 hide-md"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="preview" v-if="prevew">
      <img src="../assets/close-fill.svg" class="close_preview" @click="showPreview" />
      <slider animation="fade" style="height:100%;background:#000;" v-model="sliderIndex">
        <slider-item v-for="(item, index) in data.pics" :key="index">
          <div class="s_cover hide-sm" :style="{'background-image':'url('+item+')'}"></div>
          <div class="s_cover show-sm" :style="{'background-image':'url('+item+')'}"></div>
        </slider-item>
      </slider>
    </div>
  </Container>
</template>

<script>
import vimg from '@/components/V-img.vue';
import Container from '@/components/Container';
import { Slider, SliderItem } from 'vue-easy-slider';
import pics from '@/assets/pic.js';
import txt from '@/assets/txt.js';

export default {
  data() {
    return {
      data: '',
      pics,
      prevew: false,
      txt,
      lang: localStorage.getItem('lang') || 'cn',
      sliderIndex: 0,
    };
  },
  components: { Container, Slider, SliderItem, vimg },
  props: {},
  methods: {
    async getDetail(id) {
      //如有本地缓存则读取本地缓存.
      // const content = localStorage.getItem(id);
      // if (content !== null && content.length > 0) {
      //   this.content = JSON.parse(content);
      //   return;
      // }
      console.log(id);
      //如无则使用接口数据
      try {
        const res = await this.$cloudbase.callFunction({
          name: 'get-detail',
          data: { id },
        });
        this.data = res.result.res;
        document.title = res.result.res[this.lang].title + ' | 里表都会';
        localStorage.setItem(id, JSON.stringify(this.content));
      } catch (e) {
        this.data = e.message;
      }
    },
    showPreview(index) {
      this.prevew = !this.prevew;
      this.sliderIndex = index;
      console.log(this.sliderIndex);
    },
  },

  mounted() {
    const id = this.$route.params.id.slice(0, -5);
    this.getDetail(id);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrapper {
  background-color: #fafafa;
  padding: 8rem 0rem;
}

.cover {
  width: 100%;
  position: relative;
  z-index: -1;
  background-color: white;
}

.cover .img {
  position: fixed;
  left: 0px;
  top: 0px;
}

.preview {
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 9999;
  padding: 5rem 1rem;
  background-color: #000;
}

.info > div {
  margin-bottom: 0.2rem;
}

.info p,
.info h6 {
  display: inline-block;
}

.info h6 {
  margin-right: 0.5rem;
}

.s_cover {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
}

.close_preview {
  position: absolute;
  right: 2rem;
  top: 2rem;
  color: white;
  width: 2rem;
  height: 2rem;
}

.imgs:hover,
.close_preview:hover {
  cursor: pointer;
}
</style>
