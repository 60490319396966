<template>
  <div class="cover" v-proportion="0.56">
    <img :src="src" alt="" class="bg">
    <div style="width:100%;" v-proportion="0.56"></div>
  </div>
</template>
<script>
//import vimg from '@/components/V-img';
export default {
  //components: { vimg },
  data() {
    return {
      src: '',
    };
  },
  props: {
    page: { type: String, default: null },
  },

  methods: {
    async getPost() {
      try {
        const res = await this.$cloudbase.callFunction({ name: 'get-list', data: { type: 'cover_imgs' } });
        this.src = res.result.res.filter((item) => {
          return item.title == this.page;
        });
        this.src = this.src[0].cover_img;
      } catch (e) {
        this.src = e.message;
      }
    },
  },

  mounted() {
    this.getPost();
  },
};
</script>
<style scoped>
.cover {
  width: 100%;
  position: relative;
  z-index: -1;
  background-color: #eeeeee;
    margin-bottom: 2rem;
}

.img {
  background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom;
    background-repeat: no-repeat;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  }
  
  .bg {
    width: 100%;
    position: fixed;
    left: 0px;
    top: 114px;
    z-index: -1;
    background-color: aqua;
}
</style>