<template>
  <Container page="ums">
    <cimg page="about" />
    <!-- <pagetitle :title="txt.ums[lang].title" /> -->
    <div style="background:white;padding-top:6rem">
      <div class="container grid-xl">
        <div class="columns">
          <div class="column col-1 hide-md"></div>
          <p class="column col-7 col-md-12">{{txt.ums[lang].about}}</p>
        </div>
        <div style="height:2rem;"></div>
      </div>
      <div>
        <div style="height:2rem;"></div>
        <pagetitle :title="txt.ums[lang].awards" />
        <div class="container grid-xl awards">
          <div class="columns">
            <div class="column col-1 hide-md"></div>
            <div class="column col-10 col-md-12">
              <div class="items" v-for="(item,index) in list" :key="index">
                <h6>{{item.time}}</h6>
                <div v-html="item[lang].awards"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
          <div style="height:3rem;width:100%;"></div>
    </div>
  </Container>
</template>

<script>
import pagetitle from '@/components/PageTitle.vue';
import Container from '@/components/Container';
import cimg from '@/components/CoverImg';
import pics from '@/assets/pic';
import txt from '@/assets/txt';

export default {
  data() {
    return {
      data: '',
      pics,
      txt,
      lang: localStorage.getItem('lang') || 'cn',
      list: '',
    };
  },
  components: {
    pagetitle,
    Container,
    cimg,
  },
  props: {},
  methods: {
    async getPost() {
      try {
        const res = await this.$cloudbase.callFunction({ name: 'get-list', data: { type: 'awards' } });
        this.list = res.result.res;
        console.log(this.list);
      } catch (e) {
        this.list = e.message;
      }
    },
  },
  mounted() {
    console.log(11111111);
    this.getPost();
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.item div {
  margin-bottom: 1.2rem;
}

.items {
  margin-bottom: 3rem;
}

.items h6 {
  margin-bottom: 1rem;
}
</style>
