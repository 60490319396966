<template>
  <div>
    <Navigation :active="page" style="background-color:white;color:white" />
    <slot></slot>
    <div class="loading loading-lg" v-if="showLoading"></div>
    <!-- <div class="container grid-xl " style="background-color: white;">
          <div class="columns footer">
            <div class="column col-4"></div>
            <div class="column col-4" style="text-align:center;"><a href="https://beian.miit.gov.cn/"
                target="_blank">京ICP备2022023198号-1</a></div>
            <div class="column col-4"></div>
      </div>
    </div> -->
    <!-- 111 -->
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
export default {
  data() {
    return {
      data: '',
    };
  },
  props: ['page', 'showLoading'],
  components: { Navigation },
  methods: {},
  mounted() {},
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  height: 80px;
  background-color: white;
  display: flex;
  align-items: center;
  width: 100%;
}
</style>
