<template>
  <Container page="contact">
    <!-- <cimg page="contact" /> -->
    <!-- <pagetitle :title="txt.contact[lang].title" /> -->
    <div style="margin-top: 10%">
      <div class="container grid-xl">
        <div class="columns">
          <div class="column col-1 hide-md"></div>
          <div class="column col-5 col-md-12">
            <!-- <vimg :src="coverimg"></vimg>  -->
            <img :src="coverimg" style="width:100%;margin-bottom:2rem;" />
          </div>
          <div class="column col-1 hide-md"></div>

          <div class="column col-4 col-md-12 items contact">
            <div v-html="contents[lang].content"></div>
            <!-- 
            <h5 class="title">{{ txt.contact[lang].title }}</h5>
            <p>{{ txt.contact[lang].location }}</p>
            <p>{{ txt.contact[lang].phone }}</p>
            <p>
              <a :href="'mailto:' + txt.contact[lang].mail">{{
                txt.contact[lang].mail
              }}</a>
            </p>
            <h6>{{ txt.contact[lang].bd_txt }}</h6>
            <p>
              <a :href="'mailto:' + txt.contact[lang].bd_mail">{{
                txt.contact[lang].bd_mail
              }}</a>
            </p>
            <h6>{{ txt.contact[lang].job_mail_txt }}</h6>
            <p>
              <a :href="'mailto:' + txt.contact[lang].job_mail">{{
                txt.contact[lang].job_mail
              }}</a>
            </p>-->
          </div>
        </div>

        <div style="height: 2rem"></div>
      </div>
    </div>
    <div style="height: 3rem; width: 100%"></div>
  </Container>
</template>

<script>
// import pagetitle from '@/components/PageTitle.vue';
import Container from '@/components/Container';
//import cimg from '@/components/CoverImg';
//import vimg from '@/components/V-img';
import pics from '@/assets/pic';
import txt from '@/assets/txt';

export default {
  data() {
    return {
      data: '',
      pics,
      txt,
      lang: localStorage.getItem('lang') || 'cn',
      coverimg: '',
      contents: {},
    };
  },
  components: {
    // pagetitle,
    Container,
    //vimg,
    //cimg,
  },
  props: {},
  methods: {
    async getPost() {
      try {
        const res = await this.$cloudbase.callFunction({
          name: 'get-list',
          data: { type: 'contact' },
        });
        this.contents = res.result.res[0];
      } catch (e) {
        this.src = e.message;
      }
    },

    async getCoverImage(page) {
      try {
        const res = await this.$cloudbase.callFunction({ name: 'get-list', data: { type: 'cover_imgs' } });
        this.src = res.result.res.filter((item) => {
          return item.title == page;
        });
        console.log('res', this.src);
        this.coverimg = this.src[0].cover_img;
      } catch (e) {
        this.coverimg = e.message;
      }
    },
  },
  mounted() {
    this.getPost();
    this.getCoverImage('contact');
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.item div {
  margin-bottom: 1.2rem;
}

.items {
  margin-bottom: 3rem;
}

.items h6 {
  margin-top: 2rem;
}
</style>
